<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/exam-output' }">
        <app-i18n
          code="entities.examOutput.menu"
        ></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.examOutput.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.examOutput.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-exam-output-view-toolbar
        v-if="record"
      ></app-exam-output-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.exam.label"
          :permission="fields.exam.readPermission"
          :url="fields.exam.viewUrl"
          :value="presenter(record, 'exam')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.student.label"
          :permission="fields.student.readPermission"
          :url="fields.student.viewUrl"
          :value="presenter(record, 'student')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.questionNumber.label"
          :value="presenter(record, 'questionNumber')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.outputStatus.label"
          :value="presenter(record, 'outputStatus')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.question.label"
          :permission="fields.question.readPermission"
          :url="fields.question.viewUrl"
          :value="presenter(record, 'question')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.answer.label"
          :value="presenter(record, 'answer')"
        ></app-view-item-text>
        <app-view-item-audio
          :src="presenter(record, 'answer').path"
        />

        <app-view-item-text
          :label="fields.timeSpent.label"
          :value="presenter(record, 'timeSpent')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.mouseMovements.label"
          :value="presenter(record, 'mouseMovements')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.screenShots.label"
          :value="presenter(record, 'screenShots')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.violationCount.label"
          :value="presenter(record, 'violationCount')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ExamOutputViewToolbar from '@/modules/exam-output/components/exam-output-view-toolbar.vue';
import { ExamOutputModel } from '@/modules/exam-output/exam-output-model';

const { fields } = ExamOutputModel;

export default {
  name: 'app-exam-output-view-page',

  props: ['id'],

  components: {
    [ExamOutputViewToolbar.name]: ExamOutputViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'examOutput/view/record',
      loading: 'examOutput/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'examOutput/view/doFind',
    }),

    presenter(record, fieldName) {
      return ExamOutputModel.presenter(record, fieldName);
    },
  },
};
</script>
